import { Link } from 'react-router-dom';
import Row from './Row';
import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import useSWR from 'swr';
import {Container} from 'react-bootstrap';
const fetcher = (url) => fetch(url).then((res) => res.json());

function Home() {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const url = `http://164.92.199.160:4000/rows/${(page - 1) * 3}`;

  // Use SWR to fetch data
  const { data, error } = useSWR(hasMore ? url : null, fetcher);

  // Update items and hasMore when new data is fetched
  useEffect(() => {
    if (data && data.items) {
      setItems((prevItems) => [...prevItems, ...data.items]);
      setHasMore(data.hasMore);
    }
  }, [data]);

  // Function to fetch more data when the user scrolls to the bottom
  const fetchMoreData = () => {
    console.log(hasMore)
    if (hasMore && !error) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  if (error) return <p>Error loading data...</p>;

  return (
    <Container>
      <Link to='/about'>About</Link>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={<p>End of the list</p>}
      >
        {items.map((item, index) => (
          <div key={index} >
            <Row year={item.year} embedId={item.embedId} title={item.title} description={item.description}></Row>
          </div>
        ))}
      </InfiniteScroll>
    </Container>
  );
};


export default Home;