import React from 'react';

function Row(props) {
    return (
      <React.Fragment>
        <div> {`${props.title}`}</div>
        <div> {`${props.year}`}</div>
        <div className="ratio ratio-16x9">
          <iframe
            src={`https://www.youtube.com/embed/${props.embedId}`}
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <div> {`${props.description}`}</div>
      </React.Fragment>
    )
}

export default Row;