import { Link } from 'react-router-dom';

function About() {
    return (
      <div>
        <Link to='/'>Home</Link>
        <br/>
        Experienced Software Developer with a Master of Science in Computer Software Engineering. Over 10 years of proven expertise in JavaScript, C++, and Game Development, with significant exposure to web development frameworks.
      </div>
    )
}

export default About;